export const CadIcon = props => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={24}
    height={16}
    viewBox='0 0 24 16'
    fill='none'
    {...props}
  >
    <path fill='#fff' d='M0 .5h24v16H0V.5Z' />
    <path
      fill='red'
      d='M0 .5h6.643v16H0V.5Zm17.357 0H24v16h-6.643V.5Zm-3.018 9.637 2.358-1.179-1.179-.589V7.19L13.16 8.37l1.18-2.358h-1.18l-1.179-1.768-1.179 1.768H9.623l1.18 2.358L8.443 7.19V8.37l-1.179.59 2.358 1.178-.59 1.18h2.359v1.768h1.179v-1.769h2.358l-.59-1.179Z'
    />
  </svg>
);
