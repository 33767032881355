import React from 'react';

export const ArrowDownIcon = props => (
  <svg
    width={24}
    height={24}
    viewBox='0 0 24 24'
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    {...props}
  >
    <path
      fill='currentColor'
      d='m12 15.375-6-6L7.075 8.3 12 13.25l4.925-4.925L18 9.4l-6 5.975Z'
    />
  </svg>
);
