export const GbrIcon = props => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={24}
    height={16}
    viewBox='0 0 24 16'
    fill='none'
    {...props}
  >
    <path fill='#fff' d='M0 .5h24v16H0V.5Z' />
    <path fill='#D80027' d='M13.5.5h-3V7H0v3h10.5v6.5h3V10H24V7H13.5V.5Z' />
    <path
      fill='#0052B4'
      d='M18.46 11.283 24 14.36v-3.079h-5.54Zm-3.85 0L24 16.5v-1.476l-6.735-3.741H14.61ZM21.5 16.5l-6.89-3.828V16.5h6.89Z'
    />
    <path fill='#fff' d='M14.61 11.283 24 16.5v-1.476l-6.735-3.741H14.61Z' />
    <path fill='#D80027' d='M14.61 11.283 24 16.5v-1.476l-6.735-3.741H14.61Z' />
    <path
      fill='#0052B4'
      d='M4.235 11.282 0 13.635v-2.353h4.235Zm5.156.664V16.5H1.195l8.196-4.554Z'
    />
    <path fill='#D80027' d='M6.736 11.283 0 15.024V16.5l9.391-5.217H6.736Z' />
    <path
      fill='#0052B4'
      d='M5.541 5.717 0 2.64v3.078h5.541Zm3.85 0L0 .5v1.475l6.736 3.742H9.39ZM2.501.5l6.89 3.828V.5h-6.89Z'
    />
    <path fill='#fff' d='M9.391 5.717 0 .5v1.475l6.736 3.742H9.39Z' />
    <path fill='#D80027' d='M9.391 5.717 0 .5v1.475l6.736 3.742H9.39Z' />
    <path
      fill='#0052B4'
      d='m19.766 5.717 4.235-2.352v2.352h-4.235Zm-5.157-.663V.5h8.197l-8.197 4.554Z'
    />
    <path fill='#D80027' d='m17.265 5.717 6.736-3.742V.5l-9.392 5.217h2.656Z' />
  </svg>
);
