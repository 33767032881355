export const AudIcon = props => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={24}
    height={16}
    viewBox='0 0 24 16'
    fill='none'
    {...props}
  >
    <path fill='#10338C' d='M0 .5h24v16H0V.5Z' />
    <path
      fill='#fff'
      d='M10.395 8.486c.014-.014.023-.028.037-.042a.308.308 0 0 1-.037.042Zm-1.6 1.96.514 1.072 1.156-.267-.515 1.067.931.739-1.16.262.005 1.188-.931-.744-.927.744.005-1.188-1.16-.262.93-.74-.518-1.066 1.16.267.51-1.072Zm9.258 1.334.244.51.552-.127-.248.51.444.351-.552.122v.57l-.44-.355-.444.355.004-.57-.552-.122.445-.35-.248-.51.552.126.243-.51ZM15.78 6.353l.243.51.552-.127-.248.51.444.351-.552.126v.567l-.44-.356-.444.356.005-.567-.557-.126.444-.35-.247-.51.552.126.248-.51Zm2.273-3.097.244.51.552-.127-.248.51.444.351-.552.126v.566l-.44-.355-.444.355.004-.566-.552-.126.445-.35-.248-.51.552.126.243-.51Zm1.988 2.325.244.51.552-.127-.248.51.444.351-.552.122v.57l-.44-.355-.444.356v-.571l-.552-.122.444-.35-.248-.51.553.126.247-.51ZM18.62 8.29l.192.594h.622l-.505.365.197.594-.506-.37-.505.37.192-.594-.5-.365h.621l.192-.594Z'
    />
    <path
      fill='#fff'
      d='M11.92.5v1.432L9.81 3.106h2.11V5.88H9.155l2.765 1.535v1.071h-1.25L7.233 6.573v1.913h-2.6V6.212L.541 8.486h-.599V7.054L2.058 5.88H-.057V3.106h2.765l-2.765-1.54V.5h1.25L4.63 2.409V.5h2.601v2.274L11.326.5h.594Z'
    />
    <path
      fill='#D80027'
      d='M6.68.5H5.183v3.242h-5.24V5.24h5.24v3.247H6.68V5.239h5.24V3.742H6.68V.5Z'
    />
    <path fill='#0052B4' d='m7.232 5.88 4.688 2.606v-.74L8.561 5.88H7.232Z' />
    <path fill='#fff' d='m7.232 5.88 4.688 2.606v-.74L8.561 5.88H7.232Z' />
    <path
      fill='#D80027'
      d='m7.232 5.88 4.688 2.606v-.74L8.56 5.88H7.233Zm-3.925 0L-.057 7.747v.739L4.631 5.88H3.307Z'
    />
    <path fill='#0052B4' d='M4.631 3.106-.057.5v.734l3.364 1.872h1.324Z' />
    <path fill='#fff' d='M4.631 3.106-.057.5v.734l3.364 1.872h1.324Z' />
    <path
      fill='#D80027'
      d='M4.631 3.106-.057.5v.734l3.364 1.872h1.324Zm3.93 0 3.359-1.871V.5L7.232 3.106h1.329Z'
    />
  </svg>
);
