import Skeleton from '@material-ui/lab/Skeleton';
import clsx from 'clsx';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  placeholderRow: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridGap: theme.spacing(2)
  },
  placeholderWrapper: {
    padding: theme.spacing(0, 1)
  }
}));

export default function DropdownListPlaceholder({
  rowClassName,
  rows = 10,
  columns = 1,
  rowHeight = 36,
  ...props
}) {
  const classes = useStyles();
  return (
    <>
      {[...Array(rows).keys()].map(i => (
        <div
          key={i}
          className={clsx(classes.placeholderRow, classes.rowClassName)}
        >
          {[...Array(columns).keys()].map(j => (
            <div key={`${i}-${j}`} className={classes.placeholderWrapper}>
              <Skeleton height={rowHeight} {...props} />
            </div>
          ))}
        </div>
      ))}
    </>
  );
}
