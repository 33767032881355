import {UsdIcon} from 'src/componentsNext/icons/flags/UsdIcon';
import {EurIcon} from 'src/componentsNext/icons/flags/EurIcon';
import {GbrIcon} from 'src/componentsNext/icons/flags/GbrIcon';
import {AudIcon} from 'src/componentsNext/icons/flags/AudIcon';
import {CadIcon} from 'src/componentsNext/icons/flags/CadIcon';

import {CurrencyEnum} from './enums';

export const currenciesMap = {
  [CurrencyEnum.USD]: {
    label: 'USD ($)',
    shortLabel: 'USD',
    cardLabel: '$',
    strangeCardItemLogicLabel: '',
    value: CurrencyEnum.USD,
    icon: UsdIcon
  },
  [CurrencyEnum.EUR]: {
    label: 'EUR (€)',
    shortLabel: 'EUR',
    cardLabel: '€',
    strangeCardItemLogicLabel: '',
    value: CurrencyEnum.EUR,
    icon: EurIcon
  },
  [CurrencyEnum.GBP]: {
    label: 'GBP (£)',
    shortLabel: 'GBP',
    cardLabel: '£',
    strangeCardItemLogicLabel: '',
    value: CurrencyEnum.GBP,
    icon: GbrIcon
  },
  [CurrencyEnum.AUD]: {
    label: 'AUD (A$)',
    shortLabel: 'AUD',
    cardLabel: '$',
    strangeCardItemLogicLabel: 'AUD',
    value: CurrencyEnum.AUD,
    icon: AudIcon
  },
  [CurrencyEnum.CAD]: {
    label: 'CAD (C$)',
    shortLabel: 'CAD',
    cardLabel: '$',
    strangeCardItemLogicLabel: 'CAD',
    value: CurrencyEnum.CAD,
    icon: CadIcon
  }
};
