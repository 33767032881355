import {Button, CircularProgress} from '@material-ui/core';
import PropTypes from 'prop-types';
import {alpha, makeStyles} from '@material-ui/core/styles';
import clsx from 'clsx';
import {forwardRef} from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import {extendedTypography} from 'src/themes/themeNext/extendedTypography';

import {ArrowDownIcon} from 'src/components/icons/ArrowDownIcon';

const useStyles = makeStyles(theme => ({
  button: {
    position: 'relative',
    width: '100%',
    minWidth: 'auto',
    padding: theme.spacing(1.375, 2),
    border: `1px solid ${theme.palette.colors.inputBorder}`,
    borderRadius: theme.spacing(0.5),

    backgroundColor: alpha(theme.palette.backgrounds.dark, 0.5),
    backdropFilter: 'blur(2px)',
    transition: 'all 1s',

    '&:hover': {
      border: `1px solid ${theme.palette.colors.inputActiveBorder}`,
      backgroundColor: theme.palette.backgrounds.input
    },

    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1)
    }
  },
  transparentButton: {
    backgroundColor: 'transparent',
    border: `1px solid transparent`,
    padding: theme.spacing(1),

    '&:hover': {
      backgroundColor: 'transparent',
      border: `1px solid transparent`
    }
  },
  label: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridAutoColumns: 'max-content max-content 1fr',
    gap: theme.spacing(1),
    ...extendedTypography.body2SemiboldDesktop,

    [theme.breakpoints.down('sm')]: {
      gap: theme.spacing(0.5),
      ...extendedTypography.body2SemiboldMobile
    }
  },
  openButton: {
    border: `1px solid ${theme.palette.colors.inputActiveBorder}`
  },
  startIcon: {color: theme.palette.secondary.main, margin: 0},
  endIcon: {
    marginLeft: 'auto',
    color: theme.palette.secondary.main,
    margin: 0
  },
  rotatedIcon: {
    transform: 'rotate(180deg)'
  }
}));

export const SelectButton = forwardRef(
  (
    {
      open,
      loading,
      /* error,*/ label,
      className,
      labelClassName = '',
      withoutBg = false,
      onClick,
      ...props
    },
    ref
  ) => {
    const classes = useStyles();
    const isDownSm = useMediaQuery(theme => theme.breakpoints.down('sm'));

    return (
      <Button
        ref={ref}
        classes={{
          root: clsx(
            classes.button,
            withoutBg && classes.transparentButton,
            open && !withoutBg && classes.openButton
          ),
          label: clsx(labelClassName || classes.label),
          endIcon: classes.endIcon,
          startIcon: classes.startIcon
        }}
        className={className}
        onClick={e => onClick(e)}
        endIcon={
          <ArrowDownIcon
            width={isDownSm ? 16 : 20}
            height={isDownSm ? 16 : 20}
            className={clsx(open && classes.rotatedIcon)}
          />
        }
        {...props}
      >
        <span>{label}</span>
        {loading && <CircularProgress size={24} />}
      </Button>
    );
  }
);

SelectButton.propTypes = {
  open: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
  error: PropTypes.bool,
  label: PropTypes.node.isRequired,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  ref: PropTypes.object,
  withoutBg: PropTypes.bool
};
