import {useMemo} from 'react';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';

import {extendedTypography} from 'src/themes/themeNext/extendedTypography';

import DropdownListPlaceholder from './DropdownListPlaceholder';

const useStyles = makeStyles(theme => ({
  option: {
    display: 'flex',
    gap: theme.spacing(1),
    padding: theme.spacing(1.25, 2),
    alignItems: 'center',
    cursor: 'pointer',
    userSelect: 'none',
    transition: 'all 0.5s',

    '&:hover': {
      backgroundColor: theme.palette.backgrounds.menuItem
    }
  },
  selectedOption: {
    position: 'relative',
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.backgrounds.menuItem,

    '&:before': {
      content: "''",
      width: 2,
      height: '100%',
      position: 'absolute',
      left: 0,
      top: 0,
      backgroundColor: theme.palette.secondary.main
    }
  },
  label: {
    ...extendedTypography.body2SemiboldDesktop
  }
}));

export function singleToArray(item) {
  return Array.isArray(item) ? item : [item];
}

export const DropdownOptions = ({
  options = [],
  optionContent,
  loading = false,
  onChange,
  singleFilterSelected
}) => {
  const classes = useStyles();

  const selectedValue = useMemo(() => {
    return singleToArray(singleFilterSelected)[0]?.value;
  }, [singleFilterSelected]);

  if (loading) {
    return <DropdownListPlaceholder />;
  }

  return (
    <>
      {options.map(({label, value, title = label, icon, ...rest}) => {
        const Icon = icon;

        return (
          <div
            key={String(value)}
            role='option'
            title={title}
            className={clsx(
              classes.option,
              selectedValue === value && classes.selectedOption
            )}
            onClick={() => onChange(value)}
          >
            {icon && <Icon />}
            <Typography className={clsx(classes.label, 'optionLabel')}>
              {label}
            </Typography>
            {optionContent ? optionContent({value, ...rest}) : null}
          </div>
        );
      })}
    </>
  );
};
