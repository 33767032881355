export const EurIcon = props => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='16'
    viewBox='0 0 24 16'
    fill='none'
    {...props}
  >
    <path fill='#039' d='M0 .5h24v16H0V.5Z' />
    <path
      fill='#FC0'
      d='m11.999 2.274.2.614h.648l-.522.38.197.614-.523-.38-.523.38.2-.615-.522-.38h.646l.199-.613Zm-2.667.71.2.615h.649l-.523.38.197.614-.523-.38-.522.38.2-.614-.523-.38h.646l.2-.614ZM7.377 4.94l.2.614h.648l-.522.38.196.614-.522-.38-.523.38.2-.614-.523-.38h.646l.2-.614Zm-.711 2.667.2.614h.648l-.523.38.197.614-.522-.38-.523.38.2-.614-.523-.38h.646l.2-.614Zm.71 2.667.2.614h.649l-.522.38.196.614-.522-.38-.523.38.2-.614-.523-.38h.646l.2-.614Zm1.956 1.955.2.614h.649l-.523.38.197.614-.523-.38-.522.38.2-.614-.523-.38h.646l.2-.614Zm5.334-9.244.2.614h.648l-.522.38.196.614-.522-.38-.523.38.2-.614-.523-.38h.646l.2-.614ZM16.62 4.94l.2.614h.648l-.522.38.197.614-.523-.38-.522.38.2-.614-.523-.38h.646l.2-.614Zm.711 2.667.2.614h.649l-.523.38.197.614-.523-.38-.522.38.2-.614-.523-.38h.646l.2-.614Zm-.71 2.667.199.614h.648l-.522.38.197.614-.523-.38-.522.38.2-.614-.523-.38h.646l.2-.614Zm-4.623 2.666.2.614h.648l-.522.38.197.614-.523-.38-.523.38.2-.614-.522-.38h.646l.199-.614Zm2.667-.71.2.613h.648l-.522.38.196.614-.522-.38-.523.38.2-.614-.523-.38h.646l.2-.614Z'
    />
  </svg>
);
